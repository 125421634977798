@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #0c0c0c;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../public/images/back1.png);
  background-position: center;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --toastify-toast-width: 400px !important;
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #232325;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0d9f6e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5da280;
  cursor: pointer;
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes lessfloatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.floating {
  animation: lessfloatAnimation 3s ease-in-out infinite;
}

.less-floating {
  animation: lessfloatAnimation 3s ease-in-out infinite;
}

@keyframes buzzIn {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  25% {
    transform: translateX(20px) scale(0.75);
    opacity: 0.25;
  }
  50% {
    transform: translateX(-20px);
    opacity: 0.5;
  }
  75% {
    transform: translateX(20px);
    opacity: 0.75;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

@keyframes buzzOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    transform: scale(0.75);
    opacity: 0.25;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.buzz-in {
  animation: buzzIn 0.3s ease-in both;
}

.buzz-out {
  animation: buzzOut 0.2s ease-in-out both;
}
